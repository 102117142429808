import React from 'react';
import {useCognito} from "./Hooks/functions/useCognito";
import {AppContext} from "./Containers/Context/AppContext"
import "./App.scss"
import {HashRouter, Route, Switch} from "react-router-dom";
import Loadable from "react-loadable";

// Amplify Imports
import Amplify from 'aws-amplify';
import awsconfig from "./aws-exports";


Amplify.configure(awsconfig)

const GRAPHQL_API_REGION = awsconfig.aws_appsync_region;

const loading = () => <h2>Loading!</h2>

const Layout = Loadable({
    loader: () => import("./Containers/Layout/Layout"),
    loading,
    delay: 3500
});

const AppBody = () => {

    return (
        <HashRouter>
            <Switch>
                <Route path="/" name="Home" component={Layout}/>
            </Switch>
        </HashRouter>
    )

}

const App = () => {

    const cognitoHook = useCognito();

    return (
        <AppContext.Provider value={{cognitoHook}}>
            <AppBody/>
        </AppContext.Provider>
    );
};

export default App;
