// eslint-disable-next-line no-unused-vars
import React, {useState} from "react"
import {Auth} from "aws-amplify";

export const useCognito = () => {

    const [authenticatedUser, setAuthenticatedUser] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const Login = async (email, password) => {
        let err;
        await Auth.signIn(email, password)
            .then(authedUser => {
                console.log(authedUser);
                setAuthenticatedUser(authedUser);
                setIsLoggedIn(true);
            })
            .catch(error => {
                console.error(error);
                console.error(`Login failed with error: ${error}`);
                err = error;
            });
        return err;
    };

    const Register = async (firstName, lastName, dob, email, password) => {
        try {
            const signUpResponse = await Auth.signUp({
                username: email,
                password,
                attributes: {
                    name: firstName + " " + lastName,
                    email: email,
                    birthdate: dob
                }
            });
            console.log(signUpResponse);
            alert("Congrats on your new account!");

        } catch (error) {
            console.log("error:");
            console.log(error);
        }
    };

    return {
        Login,
        Register,
        authenticatedUser,
        isLoggedIn
    }

};