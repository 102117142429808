/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://cnk4pq3udbbyppzqq72winol2i.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-6cxxoesnqfdwtdkxuxdjlben4q",
    "aws_cognito_identity_pool_id": "us-west-2:5afe8607-9ce8-4d85-b0c3-bfc5cf8475eb",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_AvufboPXh",
    "aws_user_pools_web_client_id": "512u4h87khd8t9nk4br17jats1",
    "oauth": {}
};


export default awsmobile;
